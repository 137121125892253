.navbar { 
    margin: 1rem;
    position: sticky; 
}

.navbar .container { 
    margin: 1rem; 
    padding: 1rem;
    display: flex;
    align-items: center; 
    justify-content: space-evenly;
    /*
    border-bottom: 2px solid var(--primary-copper);
    */
    box-shadow: 0 5px var(--primary-copper)
}

.logo-container {
    display: flex; 
    align-items: center;
    font-size: 2.5rem;
    font-weight: bold;
}

.logo-container:hover {
    cursor: pointer;
}

.logo-lettering { 
    font-size: 2rem;
    padding: 5px 10px;
    margin-left: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 10%; 
    border-width: 2px;
    border-style: solid;
    border-color: var(--primary-black);
    box-shadow: 8px 8px 8px var(--primary-copper)
 }

 .logo-container .logo-name {
    margin-left: 10px;
 }  

 /*
 .logo-container:hover{ 
    transform: scale(1.1);
    transition: 0.5s ease-in-out;
 }
 */

.nav-menu { 
    display: flex; 
}

.menu-item {
    font-weight: 600;
    font-size: 1.10rem;
    padding: 0.5rem 2rem;
    color: var(--background-black);
}

.menu-item:hover{ 
    transform: scale(1.25);
    transition: 0.5s ease-in-out;
}

.hamburger { 
    display: none;
    cursor: pointer;
}

@media screen and (max-width: 1200px) { 
    .hamburger { 
        display: flex; 
    }

    .logo-container img { 
        height: 30px;
        width: auto;
     }
    
     .logo-container img:hover{ 
         transform: scale(1.25);
         transition: 0.5s ease-in-out;
     }

     .logo-container .logo-name {
        font-size: 1.15rem;
        margin-left: 10px;
    }

    .nav-menu { 
        z-index: 2;
        top: 110px;
        width: 50%;
        right: -100%; 
        height: 50vh;
        display: hidden;
        transition: 0.4s;
        position: absolute; 
        flex-direction: column;
        justify-content: start;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border-left: 2px solid var(--primary);
        border-bottom: 2px solid var(--primary);
      
    }

    .nav-menu.active {
        right: 0;
    }

    .menu-item { 
        padding: 1rem 3rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--background);
    }

    .menu-item:hover { 
        transform: none;
    }

}
