
.socials {
    margin-top: 1rem;
    text-align: center;
}

.socials a {
    font-size: 1.5em;
    padding: 0 2rem;
}

.components .clock { 
    font-size: 1.25em;
    text-align: center;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1200px) { 

    .socials a { 
        font-size: 1em;
        padding: 0 1rem;
    }

}
