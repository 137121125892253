.components { 
    margin: 1rem;
}

.components .container {
    margin: 1rem;
    padding: 1rem;
}

.components .container-slim {
    display: flex;
    margin: 0 0.25rem;
    padding: 0 0.25rem;
    align-items: center;
    justify-content: center;
}

.components .container-border {
    margin: 1rem;
    padding: 1rem;
    border-bottom: 2px solid var(--primary-copper);
}

.components h1 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}

.components h2 {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.components h3 {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}

.protocol-container { 
    width: 80%;
    margin: 0.5rem 10%;
}

.protocol-container a { 
    font-weight: bold;
    color: var(--primary-copper);
}

.container-small { 
    width: 80%;
    margin: 0.5rem 10%;
    text-align: center;
}

.container-small p { 
    margin-left: 20%; 
    margin-right: 20%;

}

.container-small a { 
    padding: 0.25rem;
    font-weight: bold;
    color: var(--primary-copper);
}

.protocol-list {
    width: 100%; 
    margin: 1rem;
    padding: 1rem;
    box-shadow: 0px 0px 8px var(--primary);
}

.protocol-row { 
    width: 100%;
    padding: 0 1rem;
}

.protocol-symbol {
    width: 10%;
    text-align: left; 
}

.protocol-name {
    width: 30%; 
    text-align: left;
}

.protocol-quantity {
    width: 30%; 
    text-align: right;
}

.protocol-price {
    width: 10%; 
    text-align: right;
}

.protocol-value {
    width: 10%; 
    text-align: right;
}

