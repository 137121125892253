
* {
    box-sizing: border-box;
}
  
body {
    margin: 0;
    font-family: Arial;
}
  
.header {
    text-align: center;
    padding: 32px;
}
  
.row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
}
  
/* Create three equal columns that sits next to each other */
.column-3 {
    -ms-flex: 33%; /* IE10 */
    flex: 33%;
    max-width: 33%;
    padding: 0 4px;
}

.column-3 img {
    width: 100%;
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
}
  
/* Create four equal columns that sits next to each other */
.column {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
}

.column img {
    width: 100%;
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
}
  
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
    .column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
}
  
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
}
  