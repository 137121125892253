.footer { 
    margin: 1rem;
    height: 15vh; 
}

.footer .container { 
    display: grid;
    padding: 2rem;
    justify-content: space-between;
    grid-template-columns: repeat(3, 1fr);
}

.footer .col-empty { 
    display: flex;
    padding: 1rem 0;
    align-items: center;
    flex-direction: column;
}

.footer .col { 
    display: flex;
    padding: 1rem 0;
    align-items: center;
    flex-direction: column;
}

.footer .bar { 
    width: 80px;
    margin: 0.5rem 0;
    text-align: center;
    border-top: 2px solid var(--primary-copper);
}

.footer a { 
    font-size: 1rem;
} 

.footer a:hover {
    transform: scale(1.25);
    transition: 0.5s ease-in-out;
}

@media screen and (max-width: 1200px) { 

    .footer .container { 
        grid-template-columns: repeat(3, 1fr);
    }

}
