.slide-container {
    width: 60%;
    text-align: center;
    padding-top: 5rem;
}
  
h3 {
    text-align: center;
}
  
.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 20%;
}
  
.each-slide span {
    padding: 10px;
    font-size: 10px;
    text-align: center;
}
  
.each-fade {
    display: flex;
}
  
.each-fade .image-container {
    width: 60%;
    overflow: hidden;
}
  
.each-fade .image-container img {
    width: 60%;
}
  
.each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}
  
.indicator {
    width: 1.5rem;
    cursor: pointer;
    text-align: center;
    border: 1px var(--primary-black) solid;
}

.indicator.active {
    color: var(--primary-black);
    background: var(--primary-copper);
}


@media screen and (max-width: 1200px) { 
    .indicator {
        width: 1rem;
        cursor: pointer;
        text-align: center;
        border: 1px var(--primary-black) solid;
    }
    
}